<template lang="pug">
	ion-header
		ion-toolbar
			div.d-flex.ion-text-start.ion-padding-start.ion-justify-content-between
				router-link(to="/")
					img(:src='require("@/plugins/app/_theme/assets/images/logo.svg")')
				span.logout(v-if="isLoggedIn" @click.stop="logout")
					a.d-flex.ion-align-items-center(href="#")
						span.mr-1 Odhlásiť sa
						ion-icon(:src="require('./icon/logout.svg')")
</template>

<script>
import { mapGetters } from 'vuex'
export default {

	computed: {
		...mapGetters('wAuth', [ 'isLoggedIn' ])
	},

	methods: {
		async logout() {
			try {
				await this.$store.dispatch('wAuth/logout')
				this.$router.push({ name: 'Login' })
			} catch (err) {
				console.error(err)
			}
		}
	}
}
</script>

<style lang="sass" scoped>
ion-toolbar
	height: 56px
	margin-top: 24px

ion-header
	box-shadow: 5px 5px 5px rgba(247, 247, 249, 1)

.logout
	cursor: pointer
	color: var(--a-color-purple)
	font-weight: bold
	text-align: center
	display: flex
	flex-direction: column

	a
		text-decoration: none

		&:hover
			text-decoration: underline
</style>
